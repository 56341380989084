<template>
  <ui-list
    :heading="heading"
    :headings="['SID', $tc('models.building', 1), '']"
    :items="sids"
  >
    <template v-slot:default="props">
      <ui-list-data>
        <el-input v-model="props.item.studentId" />
      </ui-list-data>
      <ui-list-data :text="`${props.item.buildingId}`"></ui-list-data>
      <ui-list-data small>
        <ui-link
          type="danger"
          size="small"
          uppercase
          @click="onRemoveItem(props.item)"
          >{{ $t('actions.delete') }}
        </ui-link>
      </ui-list-data>
    </template>
  </ui-list>
</template>

<script>
import UiLink from '@/components/ui/UiLink';
import UiList from '@/components/ui/UiList';
import UiListData from '@/components/ui/UiListData';
export default {
  props: {
    sids: {
      type: Array,
      required: true
    },
    heading: {
      type: String,
      required: true
    }
  },

  components: {
    UiLink,
    UiList,
    UiListData
  },

  methods: {
    onRemoveItem(item) {
      this.$emit('onRemoveItem', item);
    }
  }
};
</script>

<style></style>
