<template>
  <section>
    <ui-form-field label="SID importer">
      <el-input v-model="studentIdsInputString"></el-input>
    </ui-form-field>
    <div class="right">
      <ui-link
        type="primary"
        :disabled="!regex.test(studentIdsInputString)"
        uppercase
        @click="handleVerify"
      >
        {{ $t('actions.verify') }}
      </ui-link>
      <ui-link
        type="success"
        class="ml-2"
        uppercase
        @click="handleSaveImport"
        :disabled="!verified || !studentList.length > 0"
      >
        {{ $t('actions.save') }}
      </ui-link>
    </div>
    <student-creator-table
      class="mt-2"
      v-if="studentList.length > 0"
      heading="Nye SIDer"
      :sids="studentList"
      @onRemoveItem="handleRemoveItem"
    />
  </section>
</template>

<script>
import StudentCreatorTable from './StudentCreatorTable';
import UiFormField from '@/components/ui/UiFormField';
import UiLink from '@/components/ui/UiLink';
export default {
  props: {
    buildingId: {
      type: Number,
      required: true
    }
  },

  components: {
    StudentCreatorTable,
    UiFormField,
    UiLink
  },

  data() {
    return {
      studentIdsInputString: '',
      studentList: [],
      verified: false,
      regex: new RegExp('^[A-Z]{3,3}[A-Z0-9]{13,13}')
    };
  },

  methods: {
    handleVerify() {
      const buildingId = this.$props.buildingId;
      const valueString = this.studentIdsInputString;
      const students = this.getStudentList(valueString, buildingId);
      this.studentList = students;
      this.verified = true;
    },

    getStudentList(valueString, buildingId) {
      const ids = valueString.split(' ');
      const list = [];
      ids.forEach(sid => {
        if (sid !== ' ' && sid !== '') {
          const student = { studentId: sid, buildingId: buildingId };
          list.push(student);
        }
      });
      return list;
    },

    handleRemoveItem(item) {
      this.studentList = this.studentList.filter(
        s => s.studentId !== item.studentId
      );
    },

    handleSaveImport() {
      this.$emit('onSaveImport', this.studentList);
    }
  }
};
</script>

<style></style>
